.meet-expert-section {
    background-color: #3D4152;
    position: relative;
    .bg-image {
        filter: brightness(0.8);
        @media only screen and (max-width: 1023px) {
            filter: brightness(0.4);
            // position: absolute;
            object-fit: cover;
            inset: 0;
            height: 100%;
        }
    }
    .meet-expert-container {
        position: absolute;
        top: 14%;
        // @media only screen and (max-width: 1023px) {
        //     position: relative;
        // }
    }
    .meet-expert-inner {
        max-width: 496px;
        width: 100%;
        small {
            color: #FF6737;
            font-size: 1.5rem;
        }
        @media only screen and (max-width:768px) {
            max-width: 380px;
        }
    }
}